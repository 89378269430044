<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <OptionsHorizontal :value="this.value" :titulo="titulo" :itens="itensHabilitado"/>
        </v-col>
      </v-row>
      <v-row v-if="value.formaPgtoIntegra === 4">
        <v-col cols="12" sm="12">
          <div><v-icon color="green" small>mdi-check-outline</v-icon> Sistema integrado com o <img src="@/assets/asaas-logo.png" alt="Asaas - Integrações" style="height:20px"/>, não guardamos seus dados de pagamento.</div>
          <div><v-icon color="green" small>mdi-information-slab-box</v-icon> Preencha os dados do seu cartão para realizar o pagamento e efetivar sua inscrição.</div>
        </v-col>
        <v-col cols="12">
          <v-text-field label="* Nome Impresso no Cartão"
                    dense
                    :disabled="disabledCampos"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                    ]"
                    v-model.trim="value.holderName"/>
        </v-col>
        <v-col cols="12">
          <v-text-field label="* Número do cartão"
                    dense
                    :disabled="disabledCampos"
                    placeholder="0000 0000 0000 0000"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                    ]"
                    @input="detectCardType"
                    v-mask="'#### #### #### ####'"
                    v-model.trim="value.number">
            <template v-slot:append>
              <img :src="cardTypeIcon" alt="Card Type" class="icon"/>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field label="* Validade (mm/aaaa)"
                        dense
                        :disabled="disabledCampos"
                        v-mask="'##/####'"
                        v-model="value.mesRef"
                        :rules="[
                            this.$validators.string.required,
                      ]"/>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field label="* CCV"
                    dense
                    :disabled="disabledCampos"
                    placeholder="000"
                    v-mask="'###'"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                    ]"
                    v-model.trim="value.ccv">
            <template v-slot:append>
              <img :src="require('@/assets/icons/code.svg')" alt="Card Type" class="icon"/>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-else-if="value.formaPgtoIntegra === 2">
        <v-col cols="12" sm="12">
          <v-icon color="green" small>mdi-information-slab-box</v-icon> Para efetivar sua inscrição, faça um PIX no valor de R$ {{value.valorInscricao}} e envie o comprovante para o whatsapp: (71) 99213-9296.
          <div class="destaque-pagamento-bottom">A Chave Pix é o nosso CNPJ: 08.222.140/0001-16</div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import OptionsHorizontal from '../common/OptionsHorizontal';

export default {
  name: "CartaoCreditoForm",
  components: {OptionsHorizontal},
  props: {
    value: {
      type: Object
    },
    disabledCampos: {
      type: Boolean
    },
    titulo: {
      type: String
    },
    itens: {
      type: Array
    }
  },
  data() {
    return {
      cardType: "",
      codCartaoCredito: 4,
      cartaoCreditoEstaHabilitado: false,
    }
  },
  mounted() {
    this.verificaSeCartaoCreditoEstaHabilitado();
  },
  computed: {
    itensHabilitado() {
      return this.itens.filter(item => {
        if (item.key === 4) {
          return this.cartaoCreditoEstaHabilitado;
        }
        return true;
      });
    },
    cardTypeIcon() {
      switch (this.cardType) {
        case "Visa":
          return require('@/assets/icons/visa.svg');
        case "MasterCard":
          return require('@/assets/icons/mastercard.svg');
        case "Amex":
          return require('@/assets/icons/amex.svg');
        case "Diners Club":
          return require('@/assets/icons/diners.svg');
        case "Maestro":
          return require('@/assets/icons/maestro.svg');          
        default:
          return require('@/assets/icons/generic.svg');
      }
    },
  },
  watch: {
  },
  methods: {
    async verificaSeCartaoCreditoEstaHabilitado(){
      this.cartaoCreditoEstaHabilitado = await this.getParametroPorNome("pgtoCartaoCredito");
      console.log(this.cartaoCreditoEstaHabilitado);
    },
    detectCardType() {
      const cardNumber = this.value.number.replace(/\s+/g, ""); // Remove espaços
      // Define regex patterns for different card types
      const patterns = {
        Visa: /^4[0-9]{0,15}$/,
        MasterCard: /^(5[1-5][0-9]{0,14}|2(2[2-9][0-9]{0,14}|[3-6][0-9]{0,14}|7[01][0-9]{0,14}|720[0-9]{0,12}))$/,
        Amex: /^3[47][0-9]{0,13}$/,
        'Diners Club': /^3(?:0[0-5]|[68][0-9])[0-9]{0,11}$/,
        Maestro: /^(5018|5020|5038|56|57|58|6304|6759|676[1-3])/,
      };
      // Iterate over patterns to find the matching card type
      for (const [type, pattern] of Object.entries(patterns)) {
        if (pattern.test(cardNumber)) {
          this.cardType = type;
          return;
        }
      }
      this.cardType = "";
    },    
  }
}
</script>

<style scoped lang="scss">
.icon {
  width: 24px;
  height: 24px;
}
.destaque-pagamento-bottom {
  font-weight: bold;
  padding: 10px 0 0 0;
}
</style>