<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <!--dados da matricula-->
        <v-col cols="12" sm="6">
          <v-autocomplete dense
            :multiple="false"
            label="* Forma de Pagamento da Anuidade"
            v-model="value.formaPgtoAnuidade"
            :rules="[
                this.$validators.string.required
            ]"
            :items="this.itensFormaPgto"
            item-text="valor"
            item-value="key">
          </v-autocomplete>          
        </v-col>                    
        <v-col cols="12" sm="6">
          <v-text-field dense
              label="* Valor Anuidade"
              prefix="R$"
              :rules="[
                  this.$validators.number.required,
                  v => this.$validators.number.greaterThanOrEquals(v, 0)
              ]"
              v-model="value.valorAnuidade" type="number"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {findParametroPorNome} from '@/api/parametros';

export default {
  name: "PagamentoAnuidadeForm",
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      pix: false,
      cartao: false,
      valAnuidade: 0,
      descAnuidadeAVista: 0,
      descAnuidadeAPrazo: 0,
      itensFormaPgto: [
                {
                    key: "AV",
                    valor: "A vista"
                },
                {
                    key: "AP",
                    valor: "A Prazo"
                },
            ],
    }
  },
  mounted() {
    this.loadParametros();    
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async loadParametros(){
      let objeto = null;
      objeto = await findParametroPorNome("valAnuidade");
      this.valAnuidade = objeto.valParametro
      this.value.valorAnuidade = this.valAnuidade
    }
  }
}
</script>

<style scoped lang="scss">
</style>