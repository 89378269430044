var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","multiple":false,"label":"* Forma de Pagamento da Anuidade","rules":[
              this.$validators.string.required
          ],"items":this.itensFormaPgto,"item-text":"valor","item-value":"key"},model:{value:(_vm.value.formaPgtoAnuidade),callback:function ($$v) {_vm.$set(_vm.value, "formaPgtoAnuidade", $$v)},expression:"value.formaPgtoAnuidade"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"* Valor Anuidade","prefix":"R$","rules":[
                this.$validators.number.required,
                function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 0); }
            ],"type":"number"},model:{value:(_vm.value.valorAnuidade),callback:function ($$v) {_vm.$set(_vm.value, "valorAnuidade", $$v)},expression:"value.valorAnuidade"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }