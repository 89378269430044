<template>
  <v-flex xs12 sm12 class="py-2">
    <p v-if="titulo !== ''">{{titulo}}</p>
    <v-btn-toggle v-model="value.formaPgtoIntegra">
      <v-btn v-for="i of itens" :key="i.key" text :value="i.key">
          {{i.valor}}
      </v-btn>
    </v-btn-toggle>
  </v-flex>
</template>

<script>

export default {
  name: "OptionsHorizontal",
  components: {
  },
  props: {
    value: {
        type: Object
    },
    itens: {
        type: Array
    },
    titulo: {
        type: String ?? Number
    }
  },
  data () {
    return {
    }
  },
  computed: {
    // tituloCuston(){
    //   return this.itens.length === 1 ? "Forma de Pagamento: " : this.titulo;
    // },
  },
  methods: {
    seSoTiver1ItemSetaValorPorDefault(){
      if (this.itens.length === 1){
        this.value.formaPgtoIntegra = this.itens[0].key;
      }
    }
  },
  mounted(){
    this.seSoTiver1ItemSetaValorPorDefault();
  }
}
</script>

<style scoped lang="scss">
</style>