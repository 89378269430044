<template>
  <v-row>
    <v-col cols="12">
      <v-card class="px-3" :loading="loading">
        <v-card-title>
          <v-icon large lef>mdi-account</v-icon>
          <span class="title font-weight-bold"> Dados pessoais</span>
        </v-card-title>           
        <v-card-text class="subtitle-1 font-weight-light">     
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="* Nome"
                            dense
                            :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                            ]"
                            v-model.trim="value.nome"/>
                </v-col>
                <v-col cols="12" md="3">
                  <lookup-dominio label="* Sexo"
                                  :hidden-clear="true"
                                  hint="Selecione o sexo do cliente"
                                  v-model="value.sexo"
                                  :type="lookups.sexo"
                                  :rules="[
                                    this.$validators.notNullOrUndefined
                                  ]"/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="* Data Nascimento"
                                dense
                                type="date"
                                :rules="[
                                    this.$validators.string.required,
                                    v => this.$validators.string.greaterThanOrEquals(v, 10),
                                    v => this.$validators.string.lessThanOrEquals(v, 10),
                                ]"
                                v-model.trim="value.dtNascimento"/>
                </v-col>    
                <v-col cols="12" md="3">
                  <v-text-field label="CPF"
                                dense
                                :disabled="cpfTitularHabilitado"
                                @blur="validaCampoCpfInformado"
                                placeholder="000.000.000-00"
                                :rules="[
                                  this.$validators.string.cpf
                                ]"
                                v-mask="'###.###.###-##'"
                                v-model.trim="value.cpf"/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="CPF do Responsável (caso menor de idade)"
                                dense
                                :disabled="cpfResponHabilitado"
                                @blur="validaCampoCpfInformado"
                                placeholder="000.000.000-00"
                                :rules="[
                                    this.$validators.string.cpf
                                ]"
                                v-mask="'###.###.###-##'"
                                v-model.trim="value.cpfResponsavel"/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Id da Federação"
                            dense
                            v-model.trim="value.idFederacao"/>
                </v-col> 
                <v-col cols="12" md="3">
                  <v-text-field label="Profissão"
                            dense
                            v-model.trim="value.profissao"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>          
    </v-col>          
    <v-col cols="12">
      <v-card class="px-3" :loading="loading">
        <v-card-title>
          <v-icon large lef>mdi-at</v-icon> <span class="title font-weight-bold">Dados de Contato</span>
        </v-card-title>        
        <v-card-text class="subtitle-1 font-weight-light">     
          <v-row>
            <v-col cols="12">
              <v-row>        
                <v-col cols="12" sm="6">
                  <v-text-field label="* Email"
                                dense
                                :rules="[
                                  this.$validators.string.required,
                                  this.$validators.string.email,
                                  v => this.$validators.string.lessThanOrEquals(v, 50),
                              ]"
                              v-model.trim="value.email"/>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="* Celular"
                                dense
                                v-mask="'(##) #####-####'"
                                :rules="[
                                  this.$validators.string.required,
                                ]"
                                v-model.trim="value.celular"/>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="Telefone"
                                dense
                                v-mask="'(##) ####-####'"
                                v-model.trim="value.telefone"/>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="* CEP"
                                dense
                                v-mask="'########'"
                                :rules="[
                                  this.$validators.string.required,
                                  v => this.$validators.string.greaterThanOrEquals(v, 8),
                                  v => this.$validators.string.lessThanOrEquals(v, 8),
                                ]"
                                v-model.trim="value.cep"/>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="* Cidade"
                                dense
                                :rules="[
                                  this.$validators.string.required,
                                  v => this.$validators.string.greaterThanOrEquals(v, 1),
                                  v => this.$validators.string.lessThanOrEquals(v, 100),
                              ]"
                              v-model.trim="value.cidade"/>
                </v-col>
                <v-col cols="12" sm="3">
                  <lookup-dominio label="* Estado"
                                  :hidden-clear="true"
                                  v-model="value.estado"
                                  :type="lookups.estado"
                                  :rules="[
                                      this.$validators.string.required
                                  ]"/>
                </v-col>    
                <v-col cols="12" sm="3">
                  <v-text-field label="* Bairro"
                                dense
                                :rules="[
                                  this.$validators.string.required,
                                  v => this.$validators.string.greaterThanOrEquals(v, 1),
                                  v => this.$validators.string.lessThanOrEquals(v, 100),
                                ]"
                                v-model.trim="value.bairro"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="* Endereço"
                                dense
                                :rules="[
                                  this.$validators.string.required,
                                  v => this.$validators.string.greaterThanOrEquals(v, 1),
                                  v => this.$validators.string.lessThanOrEquals(v, 100),
                                ]"
                                v-model.trim="value.endereco"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Complemento"
                                dense
                                :rules="[
                                  v => this.$validators.string.greaterThanOrEquals(v, 1),
                                  v => this.$validators.string.lessThanOrEquals(v, 100),
                                ]"
                                v-model.trim="value.complemento"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>          
    </v-col>
    <v-col cols="12" v-if="ePrematricula">
      <v-card class="px-3" :loading="loading">
        <v-card-title>
          <v-icon large lef>mdi-map-marker-radius</v-icon>
          <span class="title font-weight-bold"> Local</span>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-light">     
          <v-row>   
            <v-col cols="12" md="12">
              <search-box
                  label="* Local"
                  :hidden-clear="true"
                  popup-label="Selecione qual o local"
                  :id.sync="value.idLocal"
                  :descricao.sync="value.descricaoIdLocal"
                  :value="local"
                  :loader-fn="loaderLocal"
                  item-key="id"
                  item-text="descricao"
                  :rules="[
                        this.$validators.notNullOrUndefined
                    ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum local encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="ePrematricula">
      <v-card class="px-3" :loading="loading">
        <v-card-title>
          <v-icon large lef>mdi-cash-multiple</v-icon> <span class="title font-weight-bold"> Pagamento</span>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-light">     
          <v-row>   
            <v-col cols="12" md="12">
             <PagamentoAnuidadeForm v-model="value"/>
             <!-- <CartaoCreditoForm v-model="value"/> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>     
    <v-col cols="12" sm="6">
      <v-checkbox
        v-model="value.declaroVerdadeira"
        color="info"
        dense
        label="Declaro que as informações acima prestadas são verdadeiras, e assumo a inteira responsabilidade pelas mesmas."
        value="S"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
        ></v-checkbox>
    </v-col>
    <v-col cols="12" sm="6">
      <v-checkbox
        v-model="value.aceitoTermo"
        color="info"
        dense
        label="Termo: Concordo que meus dados serão utilizados unica e exclusivamente para uso administrativo na Federação Baiana, Capixaba e Mineira de Golfe."
        value="S"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
        ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {buscaEnderecoViaCep} from '../../../api/correios';
import PagamentoAnuidadeForm from '../forms/PagamentoAnuidadeForm';
import CartaoCreditoForm from '../forms/CartaoCreditoForm';
import {findAll as findAllLocal} from '../../../api/locais';
import Vue from 'vue';

export default {
  name: "CadastreSeForm",
  components: {SearchBox, LookupDominio, PagamentoAnuidadeForm, CartaoCreditoForm},
  props: {
    value: {
      type: Object
    },
    ePrematricula: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
  },
  data() {
    return {
      lookups: {
        sexo: ItemDeDominio.ARR_SEXO,
        status: ItemDeDominio.STATUS_PADRAO,
        estado: ItemDeDominio.COMBO_ESTADO, 
      },
      error: null,
      cpfResponHabilitado: false,
      cpfTitularHabilitado: false,
    }
  },
  computed: {
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricaoIdLocal
      }
    },
  },
  watch: {
    'value.cep': {
      handler(newValue, oldValue) {
        this.buscaEndereco();
      }
    }
  },  
  methods: {
    async buscaEndereco(){
      if (this.value.cep !== "" && this.value.cep.length === 8){
        let endereco = await buscaEnderecoViaCep(this.value.cep);
        Vue.set(this.value,'cidade',endereco.localidade);
        Vue.set(this.value,'estado',endereco.uf);
        Vue.set(this.value,'bairro',endereco.bairro);
        Vue.set(this.value,'endereco',endereco.logradouro);
      }
    },
    validaCampoCpfInformado(){
      this.cpfResponHabilitado = false
      this.cpfTitularHabilitado = false      
      if (this.value.cpf && this.value.cpf!="") {
        this.cpfResponHabilitado = true
      } else if (this.value.cpfResponsavel && this.value.cpfResponsavel!="") {
        this.cpfTitularHabilitado = true
      } 
    },
    loaderLocal(page, search) {
      return findAllLocal(page, {
        descricao: search
      })
    },
  },
  mounted() {
    this.value.ePrematricula = (this.ePrematricula ? 1 : 0)
  }
}
</script>

<style scoped>

</style>