var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('OptionsHorizontal',{attrs:{"value":this.value,"titulo":_vm.titulo,"itens":_vm.itensHabilitado}})],1)],1),(_vm.value.formaPgtoIntegra === 4)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check-outline")]),_vm._v(" Sistema integrado com o "),_c('img',{staticStyle:{"height":"20px"},attrs:{"src":require("@/assets/asaas-logo.png"),"alt":"Asaas - Integrações"}}),_vm._v(", não guardamos seus dados de pagamento.")],1),_c('div',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-information-slab-box")]),_vm._v(" Preencha os dados do seu cartão para realizar o pagamento e efetivar sua inscrição.")],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"* Nome Impresso no Cartão","dense":"","disabled":_vm.disabledCampos,"rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.holderName),callback:function ($$v) {_vm.$set(_vm.value, "holderName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.holderName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### ####'),expression:"'#### #### #### ####'"}],attrs:{"label":"* Número do cartão","dense":"","disabled":_vm.disabledCampos,"placeholder":"0000 0000 0000 0000","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},on:{"input":_vm.detectCardType},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{staticClass:"icon",attrs:{"src":_vm.cardTypeIcon,"alt":"Card Type"}})]},proxy:true}],null,false,3294128836),model:{value:(_vm.value.number),callback:function ($$v) {_vm.$set(_vm.value, "number", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.number"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/####'),expression:"'##/####'"}],attrs:{"label":"* Validade (mm/aaaa)","dense":"","disabled":_vm.disabledCampos,"rules":[
                          this.$validators.string.required ]},model:{value:(_vm.value.mesRef),callback:function ($$v) {_vm.$set(_vm.value, "mesRef", $$v)},expression:"value.mesRef"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"label":"* CCV","dense":"","disabled":_vm.disabledCampos,"placeholder":"000","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/icons/code.svg'),"alt":"Card Type"}})]},proxy:true}],null,false,4236413818),model:{value:(_vm.value.ccv),callback:function ($$v) {_vm.$set(_vm.value, "ccv", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.ccv"}})],1)],1):(_vm.value.formaPgtoIntegra === 2)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-information-slab-box")]),_vm._v(" Para efetivar sua inscrição, faça um PIX no valor de R$ "+_vm._s(_vm.value.valorInscricao)+" e envie o comprovante para o whatsapp: (71) 99213-9296. "),_c('div',{staticClass:"destaque-pagamento-bottom"},[_vm._v("A Chave Pix é o nosso CNPJ: 08.222.140/0001-16")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }